import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  Chip,
  SvgIcon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

import {
  setDrawer,
  setIsNewChatModel,
  setSidebarClose,
} from "../redux/reducer";
import StartNewChat from "./StartNewChat";
import Sidebar from "../Components/Sidebar";
import MufgLOGO from "../assets/Images/mufg.png";
import AIModelSelect from "./AIModelSelect";
import history from "../assets/Images/history.png";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const drawer = useSelector((state) => state.drawer);
  const isChatIcon = useSelector((state) => state.isChatIcon);
  const chatHistory = useSelector((state) => state.chatHistory);
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const agentList = useSelector((state) => state.agentList);
  const user = useSelector((state) => state.user);
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [openAIAPIStatus, setOpenAIAPIStatus] = useState("none");

  const sidebarClose = useSelector((state) => state.sidebarClose);

  useEffect(() => {
    axios
      .get(`https://status.openai.com/api/v2/status.json`)
      .then(({ data }) => {
        setOpenAIAPIStatus(data.status.indicator);
      });
  }, []);

  const handleClickOpen = () => {
    dispatch(setIsNewChatModel(true));
  };

  return (
    <Box
      sx={{
        padding: "15px",
        backgroundColor: "rgba(255,255,255,1)",
        position: "absolute",
        top: "0",
        zIndex: 1,
        width: {
          xs: "calc(100% - 30px)",
          sm: !sidebarClose ? "calc(100% - 30px)" : "calc(100% - 270px)",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {sidebarClose === false && (
            <Box
              sx={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "24px",
                minWidth: "24px",
                height: "24px",
              }}
              onClick={() => dispatch(setSidebarClose(true))}
            >
              <img
                src={history}
                style={{ objectFit: "cover", width: "100%" }}
              />
            </Box>
          )}

          <Typography
            sx={{
              fontSize: {
                xs:
                  typeOfAg === "UX" || typeOfAg === "Research Assistant"
                    ? "14px"
                    : "18px",
                sm:
                  typeOfAg === "UX" || typeOfAg === "Research Assistant"
                    ? "18px"
                    : "18px",
              },
              "@media (max-width: 320px)": {
                fontSize: "12px",
              },
              color: "#ffffff",
              backgroundColor: "#73d3f9",
              padding: "10px",
              borderRadius: "30px",
            }}
            onClick={() => {
              navigate("/dashboard", { state: { tabValue: 0 } });
            }}
          >
            {["meetingAssistant", "default"].includes(typeOfAg) ? (
              "praxis/ai"
            ) : typeOfAg === "MUFG SuperChat" ? (
              <div style={{ width: "100px" }}>
                <img src={MufgLOGO} alt="MufgLOGO" />
              </div>
            ) : typeOfAg === "UX" ? (
              " UX Assistant"
            ) : (
              agentList.find((it) => it.agentName === typeOfAg)?.agentLabel
            )}
          </Typography>
          <Chip
            label={user?.subscription?.planName}
            variant="filled"
            size="small"
            sx={{
              ml: 1,
              color: "#ffffff",
              backgroundColor: "#73d3f9",
              "& .MuiChip-label": {
                color: "#ffffff",
              },
            }}
          />

          {user["custom:stripeCusId"] && (
            <Box
              sx={{
                display: "flex",
                padding: { md: "6px 12px", xs: "4px" },
                borderRadius: 0,
                cursor: "pointer",
                ml: 1,
              }}
            >
              <AIModelSelect
                dashboard="true"
                openAIAPIStatus={openAIAPIStatus}
                inputProps={{
                  IconComponent: () => null,
                  sx: {
                    paddingRight: "12px !important",
                  },
                }}
                size="small"
                sx={{
                  color: (theme) => theme.palette.grey.gray_500,
                  fontSize: { sm: "14px", md: "14px", xs: "12px" },
                  fontWeight: 700,
                  "&:hover": {
                    "&& fieldset": {
                      border: "1.5px solid #193bf5",
                    },
                  },
                  border: "1px solid #193bf5",
                  borderRadius: "25px",
                }}
              />
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", gap: { xs: 1, sm: 2 } }}>
          {isChatIcon && chatHistory.length !== 0 && (
            <Box>
              <IconButton
                className="addnewchat"
                onClick={handleClickOpen}
                sx={{
                  borderRadius: "50px",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: { md: "14px", xs: "10px" },
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}
              >
                {isMdScreen && "New Chat"}
                <AddIcon
                  sx={{
                    "@media (max-width: 320px)": {
                      fontSize: "14px",
                    },
                  }}
                />
              </IconButton>
            </Box>
          )}

          <Box>
            <IconButton
              onClick={() => dispatch(setDrawer(true))}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                borderRadius: "50px",
              }}
            >
              <MenuIcon
                sx={{
                  "@media (max-width: 320px)": {
                    fontSize: "14px",
                  },
                }}
              />
            </IconButton>
          </Box>
        </Box>

        <Sidebar
          handleClickOpen={handleClickOpen}
          drawer={drawer}
          openAIAPIStatus={openAIAPIStatus}
        />
      </Box>
      <Box>
        <StartNewChat fnCall={() => dispatch(setDrawer(false))} />
      </Box>
    </Box>
  );
};

export default Header;
